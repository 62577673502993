import request from '@/utils/request'

const user_noticeApi = {
  user_notice_list: '/user/notice/',
  user_notice_update: '/user/notice/'
}

/**
 * 获取授权车辆列表
 */
export function user_notice_list (parameter) {
  return request({
    url: user_noticeApi.user_notice_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function user_notice_create (parameter) {
  return request({
    url: user_noticeApi.user_notice_update,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
