<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="名字">
              <a-input v-model="queryParam.username" placeholder="名字"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="昵称">
              <a-input v-model="queryParam.nickname" placeholder="昵称"/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="手机">
              <a-input v-model="queryParam.cellphone" placeholder="手机"/>
            </a-form-item>
          </a-col>
          <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      <a-button @click="associated">关联用户</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"

    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="tags" slot-scope="tags">
        <a-tag
          v-for="tag in tags"
          :key="tag"
          :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
        >
          {{ tag }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-action:edit>编辑</a>
          <a-divider type="vertical"/>
          <a-dropdown>
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="showResetPassWord(record)">重置密码</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="delet(record.id)">解除关联</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleConfig(record)">消息通知配置</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <!--        <a-dropdown>
          <a class="ant-dropdown-link">
            更多 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleDelet(record)">删除</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>-->
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <associated-form
      ref="createAssociatedModal"
      v-if="associatedvisible"
      :visible="associatedvisible"
      :loading="confirmAssociatedLoading"
      @cancel="handleAssociatedCancel"
      @ok="handleAssociatedOk"
    />
    <ResetForm
      v-if="resetvisible"
      ref="createResetModal"
      :visible="resetvisible"
      :confirm-loading="resetLoading"
      :model="resetmdl"
      @ok="resetPassWord"
      @cancel="handleResetCancel"
    />
    <config-info-form
      v-if="configVisible"
      ref="configModal"
      :visible="configVisible"
      :loading="configLoading"
      :model="configmdl"
      @configcancel="handleConfigCancel"
      @configok="handleConfigOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { user_list, user_create, user_delete, user_reset_password_update } from '@/api/user'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import ResetForm from '../modules/ResetForm'
import AssociatedForm from '../modules/AssociatedForm'
import storage from 'store'
import configInfoForm from '../modules/ConfigForm'
import { CORPORATION } from '@/store/mutation-types'
import { user_corporation_delete } from '@/api/user_corporation'
import { user_notice_create } from '@/api/user_notice'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    ResetForm,
    AssociatedForm,
    configInfoForm
  },
  data () {
    return {
      resetvisible: false,
      confirmLoading: false,
      configVisible: false,
      configLoading: false,
      configmdl: {},
      resetLoading: false,
      resetmdl: {},
      loading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      associatedvisible: false,
      confirmAssociatedLoading: false,
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户名',
          dataIndex: 'username',
          ellipsis: true
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          ellipsis: true
        },
        {
          title: '手机号',
          dataIndex: 'cellphone',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return user_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {},
  methods: {
    handleConfig (record) {
      this.configmdl = record
      this.configVisible = true
      this.configLoading = false
    },
    handleConfigCancel () {
      this.configVisible = false
      const form = this.$refs.configModal.configForm
      form.resetFields() // 清理表单数据（可不做）
    },
    handleConfigOk (record) {
      const form = this.$refs.configModal.configForm
      this.configLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          values.user_id = record.id
          // 新增
          user_notice_create(values).then(res => {
            this.configVisible = false
            this.configLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.configLoading = false
        }
      })
    },
    delet (uc_id) {
      console.log(uc_id)
      user_corporation_delete({ user_id: uc_id }).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
        if (res.success) {
          this.$message.success('解除成功')
        } else {
          this.$message.warning('解除失败')
        }
      })
    },
    showResetPassWord (record) {
      this.resetmdl = record
      this.resetvisible = true
      this.resetLoading = false
    },
    resetPassWord () {
      const form = this.$refs.createResetModal.form
      this.resetLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          const param = Object.assign({}, values)
          user_reset_password_update(param, values.id).then(res => {
            if (res.success) {
              this.$message.success('成功')
            }
            this.resetvisible = false
            this.resetLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
        } else {
          this.resetLoading = false
        }
      })
    },
    handleResetCancel () {
      this.resetvisible = false
      const form = this.$refs.createResetModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    associated () {
      this.associatedvisible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAssociatedCancel () {
      this.associatedvisible = false
      const form = this.$refs.createAssociatedModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/user/UserInformation/' + record.id })
    },
    handleDelet (record) {
      user_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')

        this.$refs.table.refresh()
      })
    },
    handleAssociatedOk () {
      this.associatedvisible = false
      this.confirmAssociatedLoading = false
      this.$refs.table.refresh()
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('我是新增', values)
            // 新增
          const cor = JSON.parse(storage.get(CORPORATION))
          values.corporation_id = cor.id
          values.role = 2
          user_create(values).then(res => {
            if (res.success) {
              this.$message.success('成功')
            }
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
