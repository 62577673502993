var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建用户","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-form-item',{attrs:{"label":"用户名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username',
                        {rules: [
                          {required: true, message: '请输入'},
                          {min:6, max:50, message: '输入不符合长度限制'}
                        ]}]),expression:"['username',\n                        {rules: [\n                          {required: true, message: '请输入'},\n                          {min:6, max:50, message: '输入不符合长度限制'}\n                        ]}]"}]})],1),_c('a-form-item',{attrs:{"label":"昵称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nickname', {rules: [{required: true, message: '请输入'}]}]),expression:"['nickname', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"extra":"密码最低设置八位字符长度"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {rules: [{required: true, message: '请输入'}, {min:8, message: '输入不符合长度限制'}]}]),expression:"['password', {rules: [{required: true, message: '请输入'}, {min:8, message: '输入不符合长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"权限","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'permission_group_id',
            {
              rules: [
                { required: false, message: '请选择' },
              ],
            },
          ]),expression:"[\n            'permission_group_id',\n            {\n              rules: [\n                { required: false, message: '请选择' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"选择"},on:{"change":_vm.handlePermissionChange}},_vm._l((_vm.allPerlist),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"是否活跃","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_active', {initialValue: true, valuePropName: 'checked'}]),expression:"['is_active', {initialValue: true, valuePropName: 'checked'}]"}]})],1),_c('a-form-item',{attrs:{"label":"邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [{pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, message: '请输入正确的邮箱地址'}]}]),expression:"['email', {rules: [{pattern: /^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$/, message: '请输入正确的邮箱地址'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cellphone', {rules: [{pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/, message: '请输入正确的手机号'}]}]),expression:"['cellphone', {rules: [{pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\\d{8}$/, message: '请输入正确的手机号'}]}]"}]})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }