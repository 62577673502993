<template>
  <a-modal
    title="关联用户"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    :footer="null"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="用户名">
              <a-input v-model="queryParam.username" placeholder="请准确输入用户名"/>
            </a-form-item>
          </a-col>
          <a-button type="primary" @click="query">关联</a-button>
        </a-row>
      </a-form>
      <div>{{ userinfo.nickname }}</div>
    </div>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { user_search } from '@/api/user'
import { STable } from '@/components'
import { user_corporation_create } from '@/api/user_corporation'

// 表单字段
const fields = ['id']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete,
    STable
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      columns: [
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true
        }
      ],
      userinfo: {},
      queryParam: {
        username: ''
      },
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    this.form.setFieldsValue(pick(this.model, fields))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    query () {
      user_search(this.queryParam)
        .then(({ data }) => {
          console.log(data)
            if (data && data.id) {
              user_corporation_create({ user_id: data.id }).then((res) => {
                console.log(res)
                if (res.success) {
                  this.$message.success('关联成功')
                  this.$emit('ok')
                }
              })
            }
        })
    }
  }
}
</script>
