<template>
  <a-modal
    title="消息通知配置"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('configok', model) }"
    @cancel="() => { $emit('configcancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="configForm" v-bind="formLayout" labelAlign="left">
        <a-row :gutter="[16,16]">
          <a-col :span="4">
            <a-form-item label="保质期提醒">
              <a-switch v-decorator="['shelf_life_notice', { valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { user_notice_list } from '@/api/user_notice'
// 表单字段
const fields = ['corporation_id', 'shelf_life_notice']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 16 },
        sm: { span: 24 }
      }
    }
    return {
      configForm: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    fields.forEach(v => this.configForm.getFieldDecorator(v))
    this.getinfo()
  },
  methods: {
    getinfo () {
        this.get(this.model.id)
    },
    get (id) {
      user_notice_list({ user_id: id }).then(({ data: { entries } }) => {
        console.log(entries)
        const { configForm } = this
        const formData = pick(entries[0], fields)
        console.log('formData', formData)
        configForm.setFieldsValue(formData)
      })
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
